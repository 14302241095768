import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.find-index.js";
import moment from "moment";
import { monthYear, quarter, year } from "~/helpers/filter-helpers";
import { hasValue } from "~/helpers/common-helpers";
var PeriodTypeFilter = {
  MONTHLY: monthYear,
  QUARTERLY: quarter,
  YEARLY: year
};
export var useGraphHelpers = function useGraphHelpers() {
  var t = useTranslator();
  var formatIdleTenancyRate = function formatIdleTenancyRate(series, periodType) {
    return {
      xaxis: {
        tickAmount: series.length < 10 ? series.length : 5,
        categories: series.map(function (s) {
          return s.period;
        })
      },
      xAxisFilter: PeriodTypeFilter[periodType],
      series: [{
        name: t("PORTFOLIO_GRAPH_IDLE_TENANCY_RATE"),
        data: series.map(function (s) {
          return ((s.possibleDays - s.occupiedDays) / s.possibleDays * 100).toFixed(1);
        })
      }]
    };
  };
  var formatIdleTenancyRateRent = function formatIdleTenancyRateRent(series, periodType) {
    var percentage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return {
      xaxis: {
        tickAmount: series.length < 10 ? series.length : 5,
        categories: series.map(function (s) {
          return s.period;
        })
      },
      xAxisFilter: PeriodTypeFilter[periodType],
      series: [{
        name: t("PORTFOLIO_GRAPH_IDLE_TENANCY_RATE"),
        data: percentage ? series.map(function (s) {
          return hasValue(s.lostRent) && hasValue(s.possibleRent) ? s.lostRent / s.possibleRent * 100 : 0;
        }) : series.map(function (s) {
          var _s$lostRent;
          return (_s$lostRent = s.lostRent) !== null && _s$lostRent !== void 0 ? _s$lostRent : 0;
        })
      }]
    };
  };
  var formatEvictionRate = function formatEvictionRate(series, periodType) {
    return {
      xaxis: {
        tickAmount: series.length < 10 ? series.length : 5,
        categories: series.map(function (s) {
          return s.period;
        })
      },
      xAxisFilter: PeriodTypeFilter[periodType],
      series: [{
        name: t("PORTFOLIO_GRAPH_EVICTION_RATE"),
        data: series.map(function (s) {
          return s.evictions / (s.totalTenancies + s.evictions) * 100;
        })
      }]
    };
  };
  var formatRentAndIdleTenancyRateRentCombined = function formatRentAndIdleTenancyRateRentCombined(rentSeries, idleTenancyRateRentSeries, periodType) {
    var rent = {
      series: _toConsumableArray(rentSeries),
      translation: t("PORTFOLIO_OVERVIEW_RENT_DEVELOPMENT_GRAPH")
    };
    var idleTenancy = {
      series: _toConsumableArray(idleTenancyRateRentSeries.map(function (s) {
        var _s$lostRent2;
        return {
          period: s.period,
          amount: (_s$lostRent2 = s.lostRent) !== null && _s$lostRent2 !== void 0 ? _s$lostRent2 : 0
        };
      })),
      translation: t("PORTFOLIO_OVERVIEW_CHURN_RENT_DEVELOPMENT_GRAPH")
    };
    var longestSeries = rent.series.length > idleTenancy.series.length ? rent : idleTenancy;
    var shortestSeries = rent.series.length > idleTenancy.series.length ? idleTenancy : rent;

    // Add missing periods to the shortest series
    if (shortestSeries.series.length > 0 && longestSeries.series.length > shortestSeries.series.length) {
      longestSeries.series.forEach(function (s) {
        if (shortestSeries.series.find(function (ss) {
          return moment(ss.period).isSame(s.period);
        }) == undefined) {
          if (moment(s.period).isBefore(moment(shortestSeries.series[0].period))) {
            // Add to the beginning
            shortestSeries.series.unshift({
              period: s.period,
              amount: 0
            });
          } else {
            var indexOfLast = shortestSeries.series.findIndex(function (ss) {
              return moment(ss.period).isBefore(s.period);
            });
            // Add after the most recent period
            shortestSeries.series.splice(indexOfLast + 1, 0, {
              period: s.period,
              amount: shortestSeries.series[indexOfLast].amount
            });
          }
        }
      });
    }
    return {
      xaxis: {
        tickAmount: longestSeries.series.length < 10 ? longestSeries.series.length : 10,
        categories: longestSeries.series.map(function (s) {
          return s.period;
        })
      },
      xAxisFilter: PeriodTypeFilter[periodType],
      series: [{
        name: rent.translation,
        data: rent.series.map(function (s) {
          return s.amount;
        })
      }, {
        name: idleTenancy.translation,
        data: idleTenancy.series.map(function (s) {
          return s.amount;
        })
      }]
    };
  };
  var formatRentRate = function formatRentRate(series, periodType) {
    return {
      xaxis: {
        tickAmount: series.length < 10 ? series.length : 5,
        categories: series.map(function (s) {
          return s.period;
        })
      },
      xAxisFilter: PeriodTypeFilter[periodType],
      series: [{
        name: t("PORTFOLIO_RENT_DEVELOPMENT_GRAPH"),
        data: series.map(function (s) {
          return s.amount;
        })
      }]
    };
  };
  return {
    formatRentRate: formatRentRate,
    formatIdleTenancyRate: formatIdleTenancyRate,
    formatIdleTenancyRateRent: formatIdleTenancyRateRent,
    formatEvictionRate: formatEvictionRate,
    formatRentAndIdleTenancyRateRentCombined: formatRentAndIdleTenancyRateRentCombined
  };
};