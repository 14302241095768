import { getColorFromHex } from "~/helpers/color-helpers"


export const getNumberOfProperties = function (portfolio) {
  return portfolio.assetManagementProperties.metadata.totalCount;
};

export const getNumberOfUnits = function (portfolio) {
  return portfolio.assetManagementProperties.items.reduce((acc, property) => {
    return acc + property.assetManagementTenancies.metadata.totalCount;
  }, 0);
};

export const getPortfolioColors = function () {
  return ["white", "yellow", "purple", "blue", "pink", "green", "red", "brown", "magenta", "cyan", "maroon", "autumn"];
};

export const getHexFromColor = function (color) {
  if (color == "white") return "#FFFFFF";

  const hex = getCssVariableValue(`--color-${color}-500`).trim();

  return hex ? hex : "#1C1C1C";
};

function getCssVariableValue(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName);
}

export function getPortfolioColorForeground(portfolio) {
  const colorHex = portfolio?.color ?? "";

  return `--color-${getColorFromHex(colorHex)}-900`;
}

export function getPortfolioColorBackground(portfolio) {
  const colorHex = portfolio?.color ?? "";

  if (getColorFromHex(colorHex) == "white") return `--color-accent-100`;

  return `--color-${getColorFromHex(colorHex)}-50`;
}
