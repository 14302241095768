import Vue from "vue";
import VueI18N from "vue-i18n";
import { Plugin } from "@nuxt/types";

import ENGLISH_TRANSLATIONS from "~/translations/en.json";
import DANISH_TRANSLATIONS from "~/translations/da.json";
import DEV_TRANSLATIONS from "~/translations/dev.json";

const LANGUAGE_DEFAULT = "da";

Vue.use(VueI18N);

const TRANSLATIONS = {
  "en-gb": ENGLISH_TRANSLATIONS,
  da: DANISH_TRANSLATIONS,
  dev: DEV_TRANSLATIONS,
};

const i18n = new VueI18N({
  locale: getNavLanguage(),
  silentTranslationWarn: true,
  messages: TRANSLATIONS,
});

export default <Plugin>function ({ app }) {
  app.i18n = i18n;
};

function getNavLanguage() {
  let navLang = navigator.language;
  if (navLang && (navLang.startsWith("en-gb") || navLang.startsWith("en-US"))) {
    return "en-gb";
  }

  return LANGUAGE_DEFAULT;
}
