import moment from "moment";
import { AssetManagementAgentType } from "~/graphql/generated/graphql";
import { type TranslationKey } from "../common-helpers";

export type Valuation = {
  isAgentSelected?: Nullable<boolean>;
  agentSelectedDateFrom?: Nullable<string>;
  askingPrice?: Nullable<number>;
};

/**
 * Ported from old MIA system and calculated as follows:
 *
 * brokerMandata = agentSelectedDateFrom + 90 days - today
 */
export const getBrokerMandate = (valuations: Valuation[]) => {
  const selectedBroker = valuations?.find((x) => x.isAgentSelected);

  if (selectedBroker == null || selectedBroker.agentSelectedDateFrom == null) {
    return 0;
  }

  const today = moment();
  const fromDatePlus90 = moment(selectedBroker.agentSelectedDateFrom).add(90, "days");

  return fromDatePlus90.diff(today, "days") * -1;
};

export const getAgentTypeTranslationKey = (type: AssetManagementAgentType): TranslationKey => `PORTFOLIO_AGENT_TYPES_${type}`;
