import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1507b58f = () => interopDefault(import('../pages/analytics/index.vue' /* webpackChunkName: "pages/analytics/index" */))
const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _aa576342 = () => interopDefault(import('../pages/datawarehouse.vue' /* webpackChunkName: "pages/datawarehouse" */))
const _a7d2148e = () => interopDefault(import('../pages/datawarehouse/catalogue.vue' /* webpackChunkName: "pages/datawarehouse/catalogue" */))
const _7674e036 = () => interopDefault(import('../pages/datawarehouse/catalogue/categories/index.vue' /* webpackChunkName: "pages/datawarehouse/catalogue/categories/index" */))
const _73c16102 = () => interopDefault(import('../pages/datawarehouse/catalogue/custom-fields/index.vue' /* webpackChunkName: "pages/datawarehouse/catalogue/custom-fields/index" */))
const _184ae734 = () => interopDefault(import('../pages/datawarehouse/catalogue/portfolios/index.vue' /* webpackChunkName: "pages/datawarehouse/catalogue/portfolios/index" */))
const _1178cc6d = () => interopDefault(import('../pages/datawarehouse/catalogue/categories/helpers.ts' /* webpackChunkName: "pages/datawarehouse/catalogue/categories/helpers" */))
const _32bfbd4e = () => interopDefault(import('../pages/datawarehouse/overview.vue' /* webpackChunkName: "pages/datawarehouse/overview" */))
const _d603ab94 = () => interopDefault(import('../pages/datawarehouse/property-mapping.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping" */))
const _14344f71 = () => interopDefault(import('../pages/datawarehouse/property-mapping/flags/index.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/flags/index" */))
const _5124c752 = () => interopDefault(import('../pages/datawarehouse/property-mapping/portfolios/index.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/portfolios/index" */))
const _60c172e3 = () => interopDefault(import('../pages/datawarehouse/property-mapping/types/index.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/types/index" */))
const _1220412f = () => interopDefault(import('../pages/datawarehouse/property-mapping/flags/PropertyFlagValueTickbox.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/flags/PropertyFlagValueTickbox" */))
const _49b0fa51 = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping" */))
const _29fe61e8 = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping/flags/index.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping/flags/index" */))
const _ac9a3d8a = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping/responsible/index.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping/responsible/index" */))
const _26601723 = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping/flags/TenancyFlagValueTickbox.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping/flags/TenancyFlagValueTickbox" */))
const _d9b0731a = () => interopDefault(import('../pages/datawarehouse/testdata.js' /* webpackChunkName: "pages/datawarehouse/testdata" */))
const _9df0fa72 = () => interopDefault(import('../pages/datawarehouse/components.old/TableCellMessage.vue' /* webpackChunkName: "pages/datawarehouse/components.old/TableCellMessage" */))
const _582bf8e2 = () => interopDefault(import('../pages/datawarehouse/components.old/TableCellStatus.vue' /* webpackChunkName: "pages/datawarehouse/components.old/TableCellStatus" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4023565e = () => interopDefault(import('../pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _1ea48840 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _0a2317a7 = () => interopDefault(import('../pages/tenancies/index.vue' /* webpackChunkName: "pages/tenancies/index" */))
const _199cd4f8 = () => interopDefault(import('../pages/testbed/index.vue' /* webpackChunkName: "pages/testbed/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _55afa106 = () => interopDefault(import('../pages/portfolio/property/_propertyId.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId" */))
const _7cb5862e = () => interopDefault(import('../pages/portfolio/property/_propertyId/actionplans/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/actionplans/index" */))
const _0fda0c96 = () => interopDefault(import('../pages/portfolio/property/_propertyId/balance/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/balance/index" */))
const _5727e726 = () => interopDefault(import('../pages/portfolio/property/_propertyId/capexes/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/capexes/index" */))
const _2c9af11e = () => interopDefault(import('../pages/portfolio/property/_propertyId/opexes/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/opexes/index" */))
const _5718ab95 = () => interopDefault(import('../pages/portfolio/property/_propertyId/overview/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/overview/index" */))
const _e29672f4 = () => interopDefault(import('../pages/portfolio/property/_propertyId/sales/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/sales/index" */))
const _628e7529 = () => interopDefault(import('../pages/portfolio/property/_propertyId/units/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/units/index" */))
const _eae47da4 = () => interopDefault(import('../pages/portfolio/property/_propertyId/valuations/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/valuations/index" */))
const _2314873c = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId" */))
const _788edc23 = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/capexes/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/capexes/index" */))
const _374fd29f = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/overview/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/overview/index" */))
const _6d940bbc = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/sales/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/sales/index" */))
const _123a1b50 = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/tenant/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/tenant/index" */))
const _39974db8 = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/valuations/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/valuations/index" */))
const _0126f1d6 = () => interopDefault(import('../pages/portfolio/_portfolioId.vue' /* webpackChunkName: "pages/portfolio/_portfolioId" */))
const _65e7ac65 = () => interopDefault(import('../pages/portfolio/_portfolioId/balance/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/balance/index" */))
const _cd2fedb8 = () => interopDefault(import('../pages/portfolio/_portfolioId/companies/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/companies/index" */))
const _a4d7bc7e = () => interopDefault(import('../pages/portfolio/_portfolioId/opex/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/opex/index" */))
const _7a81f4b4 = () => interopDefault(import('../pages/portfolio/_portfolioId/overview/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/overview/index" */))
const _45b6f860 = () => interopDefault(import('../pages/portfolio/_portfolioId/properties/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/properties/index" */))
const _ee7f69b2 = () => interopDefault(import('../pages/portfolio/_portfolioId/tenancies/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/tenancies/index" */))
const _63f91aca = () => interopDefault(import('../pages/portfolio/_portfolioId/composables/useQueryWithPeriod.ts' /* webpackChunkName: "pages/portfolio/_portfolioId/composables/useQueryWithPeriod" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics",
    component: _1507b58f,
    name: "analytics"
  }, {
    path: "/auth",
    component: _2161b8d6,
    name: "auth"
  }, {
    path: "/datawarehouse",
    component: _aa576342,
    name: "datawarehouse",
    children: [{
      path: "catalogue",
      component: _a7d2148e,
      name: "datawarehouse-catalogue",
      children: [{
        path: "categories",
        component: _7674e036,
        name: "datawarehouse-catalogue-categories"
      }, {
        path: "custom-fields",
        component: _73c16102,
        name: "datawarehouse-catalogue-custom-fields"
      }, {
        path: "portfolios",
        component: _184ae734,
        name: "datawarehouse-catalogue-portfolios"
      }, {
        path: "categories/helpers",
        component: _1178cc6d,
        name: "datawarehouse-catalogue-categories-helpers"
      }]
    }, {
      path: "overview",
      component: _32bfbd4e,
      name: "datawarehouse-overview"
    }, {
      path: "property-mapping",
      component: _d603ab94,
      name: "datawarehouse-property-mapping",
      children: [{
        path: "flags",
        component: _14344f71,
        name: "datawarehouse-property-mapping-flags"
      }, {
        path: "portfolios",
        component: _5124c752,
        name: "datawarehouse-property-mapping-portfolios"
      }, {
        path: "types",
        component: _60c172e3,
        name: "datawarehouse-property-mapping-types"
      }, {
        path: "flags/PropertyFlagValueTickbox",
        component: _1220412f,
        name: "datawarehouse-property-mapping-flags-PropertyFlagValueTickbox"
      }]
    }, {
      path: "tenancy-mapping",
      component: _49b0fa51,
      name: "datawarehouse-tenancy-mapping",
      children: [{
        path: "flags",
        component: _29fe61e8,
        name: "datawarehouse-tenancy-mapping-flags"
      }, {
        path: "responsible",
        component: _ac9a3d8a,
        name: "datawarehouse-tenancy-mapping-responsible"
      }, {
        path: "flags/TenancyFlagValueTickbox",
        component: _26601723,
        name: "datawarehouse-tenancy-mapping-flags-TenancyFlagValueTickbox"
      }]
    }, {
      path: "testdata",
      component: _d9b0731a,
      name: "datawarehouse-testdata"
    }, {
      path: "components.old/TableCellMessage",
      component: _9df0fa72,
      name: "datawarehouse-components.old-TableCellMessage"
    }, {
      path: "components.old/TableCellStatus",
      component: _582bf8e2,
      name: "datawarehouse-components.old-TableCellStatus"
    }]
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/portfolio",
    component: _4023565e,
    name: "portfolio"
  }, {
    path: "/properties",
    component: _1ea48840,
    name: "properties"
  }, {
    path: "/settings",
    component: _2c20a960,
    name: "settings"
  }, {
    path: "/tenancies",
    component: _0a2317a7,
    name: "tenancies"
  }, {
    path: "/testbed",
    component: _199cd4f8,
    name: "testbed"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/portfolio/property/:propertyId?",
    component: _55afa106,
    name: "portfolio-property-propertyId",
    children: [{
      path: "actionplans",
      component: _7cb5862e,
      name: "portfolio-property-propertyId-actionplans"
    }, {
      path: "balance",
      component: _0fda0c96,
      name: "portfolio-property-propertyId-balance"
    }, {
      path: "capexes",
      component: _5727e726,
      name: "portfolio-property-propertyId-capexes"
    }, {
      path: "opexes",
      component: _2c9af11e,
      name: "portfolio-property-propertyId-opexes"
    }, {
      path: "overview",
      component: _5718ab95,
      name: "portfolio-property-propertyId-overview"
    }, {
      path: "sales",
      component: _e29672f4,
      name: "portfolio-property-propertyId-sales"
    }, {
      path: "units",
      component: _628e7529,
      name: "portfolio-property-propertyId-units"
    }, {
      path: "valuations",
      component: _eae47da4,
      name: "portfolio-property-propertyId-valuations"
    }]
  }, {
    path: "/portfolio/tenancy/:tenancyId?",
    component: _2314873c,
    name: "portfolio-tenancy-tenancyId",
    children: [{
      path: "capexes",
      component: _788edc23,
      name: "portfolio-tenancy-tenancyId-capexes"
    }, {
      path: "overview",
      component: _374fd29f,
      name: "portfolio-tenancy-tenancyId-overview"
    }, {
      path: "sales",
      component: _6d940bbc,
      name: "portfolio-tenancy-tenancyId-sales"
    }, {
      path: "tenant",
      component: _123a1b50,
      name: "portfolio-tenancy-tenancyId-tenant"
    }, {
      path: "valuations",
      component: _39974db8,
      name: "portfolio-tenancy-tenancyId-valuations"
    }]
  }, {
    path: "/portfolio/:portfolioId",
    component: _0126f1d6,
    name: "portfolio-portfolioId",
    children: [{
      path: "balance",
      component: _65e7ac65,
      name: "portfolio-portfolioId-balance"
    }, {
      path: "companies",
      component: _cd2fedb8,
      name: "portfolio-portfolioId-companies"
    }, {
      path: "opex",
      component: _a4d7bc7e,
      name: "portfolio-portfolioId-opex"
    }, {
      path: "overview",
      component: _7a81f4b4,
      name: "portfolio-portfolioId-overview"
    }, {
      path: "properties",
      component: _45b6f860,
      name: "portfolio-portfolioId-properties"
    }, {
      path: "tenancies",
      component: _ee7f69b2,
      name: "portfolio-portfolioId-tenancies"
    }, {
      path: "composables/useQueryWithPeriod",
      component: _63f91aca,
      name: "portfolio-portfolioId-composables-useQueryWithPeriod"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
