// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_gkzPl{align-items:center;align-self:center;box-sizing:border-box;display:flex;flex-direction:column;justify-content:center;padding:.5rem;position:relative}.container_gkzPl:not(:last-child){margin-bottom:1.5rem}.container_gkzPl.active_nvx3g,.container_gkzPl:hover{background-color:var(--color-primary);color:var(--color-white)!important;cursor:pointer}.label_tUHEn{align-items:center;background-color:var(--color-primary);box-shadow:var(--box-shadow-small);display:none;height:2rem;left:3.75rem;padding-left:.5rem;padding-right:1rem;position:absolute;z-index:999}.chrome-navigation-controls-item:hover .chrome-navigation-controls-item-label{display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_gkzPl",
	"active": "active_nvx3g",
	"label": "label_tUHEn"
};
module.exports = ___CSS_LOADER_EXPORT___;
