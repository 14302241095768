import { render, staticRenderFns } from "./Autocomplete.vue?vue&type=template&id=84ba8352"
import script from "./Autocomplete.vue?vue&type=script&setup=true&lang=ts"
export * from "./Autocomplete.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,CompositionLayoutSingle: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/Composition.vue').default,ChromeAutocompleteItem: require('/app/components/Chrome/ChromeAutocompleteItem.vue').default,ChromeAutocomplete: require('/app/components/Chrome/ChromeAutocomplete.vue').default})
