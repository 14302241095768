import moment from "moment";
import { formatString } from "./common-helpers";

export const capitalize = (s: string) => {
  return s.replace(/^\p{CWU}/u, (char) => char.toLocaleUpperCase(navigator.language));
};
export const capitalizeAll = (s: string) => s.replace(/(\b[a-z](?!\s))/g, (char) => char.toLocaleUpperCase(navigator.language));

export const monthYear = (input: Nullable<string>) => formatString(input, (input) => moment(input).format("MMM YY"));
export const quarter = (input: Nullable<string>) =>
  formatString(input, (input) => {
    const momentDate = moment(input);
    return `Q${momentDate.quarter()} ${momentDate.year()}`;
  });
export const year = (input: Nullable<string>) => formatString(input, (input) => moment(input).format("YYYY"));
export const dateRange = (dateFrom: Nullable<string>, dateTo: Nullable<string>, format: string = "Do MMM YYYY") => {
  if (!dateFrom && !dateTo) return "–";

  if (!dateFrom) {
    return `– ${moment(dateTo, "YYYY-MM-DD").format(format)}`;
  }

  if (!dateTo) {
    return `${moment(dateFrom, "YYYY-MM-DD").format(format)} –`;
  }

  return `${moment(dateFrom, "YYYY-MM-DD").format(format)} – ${moment(dateTo, "YYYY-MM-DD").format(format)}`;
};
