// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/Align_Logo_Fade.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_k4oWM{background-color:var(--color-white);height:100%;position:absolute;width:100%;z-index:1}.loader_Ag1hG{bottom:calc(50% - 2rem);height:8rem;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:left center;mask-position:left center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:contain;mask-size:contain;position:absolute;top:calc(50% - 2rem);transform:translateZ(0);width:8rem;z-index:2}.loader_Ag1hG.centered_hVgw7{left:calc(50% - 2rem);right:calc(50% - 2rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_k4oWM",
	"loader": "loader_Ag1hG",
	"centered": "centered_hVgw7"
};
module.exports = ___CSS_LOADER_EXPORT___;
