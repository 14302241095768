import type { ComputedRef, InjectionKey, Ref } from "vue";
import moment from "moment";
import type { Moment } from "moment";
import {
  MetricPeriodType,
  type IncomeAccountSpecificationsQuery,
  type RawBalanceAccountSpecificationsQuery,
  type IncomeStatementAccountSpecificationsPortfolioQuery,
  type RawBalanceAccountSpecificationsPortfolioQuery,
  type BalancePropertyQuery,
  type BalancePortfolioQuery,
  type ResultsPropertyQuery,
  type ResultsPortfolioQuery,
} from "~/graphql/generated/graphql";
import type { DateRangeConfig, NamespacePortfolioPropertyBalance } from "../user-preferences-helpers";
import { filterWithValue, type TranslationKey } from "../common-helpers";
import { exportAddSheetHeaderRow, type ExcelHeader, type ExcelValueObject, exportDownloadSheet, exportGenerateSheet } from "../export-helpers";
import { type ColumnDataObject, type BudgetCategoryMap, type Budget } from "./portfolio-category-helpers";
import { capitalize } from "../filter-helpers";
import { useTranslator } from "~/composables/useTranslator";

export const fitDate = (date: Moment, periodType: MetricPeriodType) => {
  switch (periodType) {
    case "MONTHLY":
      return date.startOf("month");
    case "QUARTERLY":
      return date.startOf("quarter");
    case "YEARLY":
      return date.startOf("year");
    default:
      return date;
  }
};

export const fitDateString = (dateStr: string, periodType: MetricPeriodType) => fitDate(moment.utc(dateStr), periodType);

export const getPeriodHeader = (period: Moment, periodType: MetricPeriodType | null) => {
  switch (periodType) {
    case "MONTHLY":
      return capitalize(period.format("MMM YYYY"));
    case "QUARTERLY":
      return `Q${period.quarter()} ${period.year()}`;
    case "YEARLY":
      return capitalize(period.format("YYYY"));
    default:
      return " - ";
  }
};

export type FinanceCell = {
  period: string;
  isBlueprint: boolean;
  order: number;
};

export type CategoryCell = Omit<
  NonNullable<BalancePropertyQuery & BalancePortfolioQuery & ResultsPropertyQuery & ResultsPortfolioQuery>["categoryCells"][number],
  "__typename"
>;

export type RowValueType = RowValue["type"];

export interface RowValueBase {
  cellKey: string;
  value: number;
  type: RowValueType;
  index: number;
}

export interface BudgetRowValue extends RowValueBase {
  type: "budget";
  budgetId: string;
}

export interface BudgetForecastRowValue extends RowValueBase {
  type: "budgetForecast";
  budgetId: string;
}

export interface TotalRowValue extends RowValueBase {
  type: "totalCostWithVat";
}

export interface ChangeRowValue extends RowValueBase {
  type: "change";
}

export interface ChangePercentageRowValue extends RowValueBase {
  type: "changePercentage";
}

export type RowValue = BudgetRowValue | BudgetForecastRowValue | TotalRowValue | ChangeRowValue | ChangePercentageRowValue;

export type RowValueHeader = {
  label: string;
  colSpan: number;
  cellKey: string;
  even: boolean;
  type: RowValueType;
};

export type RowPeriodHeader = {
  period: Moment;
  label: string;
  colSpan: number;
  even: boolean;
};

export type RowValues = { values: RowValue[]; isBlueprint: boolean };

export type ExportRow = { values: RowValue[]; name: string; level: number; sum?: boolean; expanded?: boolean };

export type ExportRows = ExportRow[];

export type AccountSpecificationsQuery = IncomeAccountSpecificationsQuery &
  IncomeStatementAccountSpecificationsPortfolioQuery &
  RawBalanceAccountSpecificationsQuery &
  RawBalanceAccountSpecificationsPortfolioQuery;

export type AccountCell = NonNullable<AccountSpecificationsQuery["accountSpecifications"]>[number]["accounts"][number];

export type AccountType = "results" | "balance";

export type AccountRow = {
  id: string;
  categoryId: string;
  name: string;
  values: ColumnDataObject[];
  type: AccountType;
  accountNumber: string;
};

export type FinancialStatementsInput = {
  id: string;
  entryDateStart: Moment;
  entryDateEnd: Moment;
};

export type StatementsModalContext = {
  account: Ref<AccountRow | undefined>;
  open: Ref<boolean>;
  period: Ref<Moment | undefined>;
};

export type BalanceTableContext = {
  columns: Moment[];
  categoryAccountsLoading: Ref<string[]>;
  periodHeaders: RowPeriodHeader[];
};

export const getAccountAggregationKey = (account?: { accountNumber?: string | null; accountName?: string | null } | null) => account?.accountNumber;

export const BalancePreferencesInjectionKey = Symbol("balancePreferences") as InjectionKey<ComputedRef<NamespacePortfolioPropertyBalance>>;
export const BalanceTableContextInjectionKey = Symbol("balanceTableContext") as InjectionKey<ComputedRef<BalanceTableContext>>;
export const StatementsModalContextInjectionKey = Symbol("statementsModalContext") as InjectionKey<StatementsModalContext>;
export const PropertyIdsInjectionKey = Symbol("propertyIds") as InjectionKey<ComputedRef<string[]>>;
export const TenancyIdsInjectionKey = Symbol("tenancyIds") as InjectionKey<ComputedRef<string[]>>;
export const BudgetCategoryMapInjectionKey = Symbol("budgetCategoryMap") as InjectionKey<ComputedRef<BudgetCategoryMap>>;
export const BudgetBalanceTotalsInjectionKey = Symbol("budgetBalanceTotals") as InjectionKey<ComputedRef<Map<string, Map<string, number>>>>;

export enum BalanceAndIncomeQueryType {
  Property,
  Portfolio,
}

export type BalanceAndIncomeQueryParameters = {
  propertyId?: string | null;
  portfolioId?: string | null;
};

export const balanceDateRangeYearly = ["currentPreviousYear", "latest3", "latest5", "custom"] as const;
export const balanceDateRangeQuarterly = ["latest4", "latest8", "currentYear", "previousYear", "custom"] as const;
export const balanceDateRangeMonthly = ["currentYear", "previousYear", "currentQuarter", "previousQuarter", "latest12", "custom"] as const;
export type BalanceDateRange = (typeof balanceDateRangeYearly | typeof balanceDateRangeQuarterly | typeof balanceDateRangeMonthly)[number];

export const getBalancePeriodLabel = (balanceDateRange: BalanceDateRange): TranslationKey =>
  `PORTFOLIO_BALANCE_SETTINGS_DATERANGE_${balanceDateRange.toUpperCase() as Uppercase<BalanceDateRange>}`;
export const getBalanceDateRangeOption = (balanceDateRange: BalanceDateRange) => {
  const translator = useTranslator();

  return {
    id: balanceDateRange,
    name: translator(getBalancePeriodLabel(balanceDateRange)),
  };
};

export const setToPreviousPeriod = (date: Moment, periodType: MetricPeriodType) => {
  switch (periodType) {
    case MetricPeriodType.Monthly:
      date.subtract(1, "month");
      date.startOf("month");
      break;
    case MetricPeriodType.Quarterly:
      date.subtract(1, "quarter");
      date.startOf("quarter");
      break;
    case MetricPeriodType.Yearly:
      date.subtract(1, "year");
      date.startOf("year");
      break;
  }
};

export const getBudgetDateRange = (budget: Budget) => {
  return getDateRange(
    "custom",
    MetricPeriodType.Monthly,
    moment.utc(budget.validFrom).startOf("year").format("YYYY-MM-DD"),
    moment.utc(budget.validTo).endOf("year").format("YYYY-MM-DD")
  );
};

export const getDateConfigRange = (config: DateRangeConfig) =>
  getDateRange(config.dateRange, config.periodType, config.customDateStart, config.customDateEnd, config.referenceDate);

export const getDateRange = (
  balanceDateRange: BalanceDateRange,
  periodType: MetricPeriodType,
  customDateStart?: string,
  customDateEnd?: string,
  referenceDate?: string
): { from: Moment; to: Moment } => {
  const newDate = () => moment.utc(referenceDate);
  switch (balanceDateRange) {
    case "currentPreviousYear":
      return { from: fitDate(newDate(), MetricPeriodType.Yearly).subtract(1, "year"), to: fitDate(newDate(), MetricPeriodType.Yearly) };
    case "currentYear":
      return { from: fitDate(newDate(), MetricPeriodType.Yearly), to: fitDate(newDate(), MetricPeriodType.Yearly).endOf("year") };
    case "previousYear":
      return {
        from: fitDate(newDate(), MetricPeriodType.Yearly).subtract(1, "year"),
        to: fitDate(newDate(), MetricPeriodType.Yearly).subtract(1, "year").endOf("year"),
      };
    case "latest3":
      return {
        from: fitDate(newDate(), MetricPeriodType.Yearly).subtract(3, "year"),
        to: fitDate(newDate(), MetricPeriodType.Yearly).subtract(1, "year"),
      };
    case "latest5":
      return {
        from: fitDate(newDate(), MetricPeriodType.Yearly).subtract(5, "year"),
        to: fitDate(newDate(), MetricPeriodType.Yearly).subtract(1, "year"),
      };
    case "currentQuarter":
      return { from: fitDate(newDate(), MetricPeriodType.Quarterly), to: fitDate(newDate(), MetricPeriodType.Quarterly).endOf("quarter") };
    case "previousQuarter":
      return {
        from: fitDate(newDate(), MetricPeriodType.Quarterly).subtract(1, "quarter"),
        to: fitDate(newDate(), MetricPeriodType.Quarterly).subtract(1, "quarter").endOf("quarter"),
      };
    case "latest4":
      return {
        from: fitDate(newDate(), MetricPeriodType.Quarterly).subtract(4, "quarter"),
        to: fitDate(newDate(), MetricPeriodType.Quarterly).subtract(1, "quarter"),
      };
    case "latest8":
      return {
        from: fitDate(newDate(), MetricPeriodType.Quarterly).subtract(8, "quarter"),
        to: fitDate(newDate(), MetricPeriodType.Quarterly).subtract(1, "quarter"),
      };
    case "latest12":
      return {
        from: fitDate(newDate(), MetricPeriodType.Monthly).subtract(12, "month"),
        to: fitDate(newDate(), MetricPeriodType.Monthly).subtract(1, "month"),
      };
    case "custom":
      const from = moment.utc(customDateStart, "YYYY-MM-DD").startOf("month");
      const to = moment.utc(customDateEnd, "YYYY-MM-DD").endOf("month");

      switch (periodType) {
        case MetricPeriodType.Monthly:
          from.startOf("month");
          to.endOf("month");
          break;
        case MetricPeriodType.Quarterly:
          from.startOf("quarter");
          to.endOf("quarter");
          break;
        case MetricPeriodType.Yearly:
          from.startOf("year");
          to.endOf("year");
          break;
      }

      return { from, to };
  }
};

export const getDateRangeEntries = ({ from, to }: { from: Moment; to: Moment }, periodType: MetricPeriodType) => {
  const entries: Moment[] = [];

  const current = fitDate(moment.utc(to), periodType);

  while (current.isSameOrAfter(from)) {
    entries.unshift(moment.utc(current));

    setToPreviousPeriod(current, periodType);
  }

  return entries;
};

export const extractColumnsFromCategories = (periodType: MetricPeriodType, cells: { period: string }[]) => {
  const periods = new Set<string>();

  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];

    periods.add(cell.period);
  }

  return Array.from(periods)
    .map((p) => fitDateString(p, periodType))
    .filter((a, b, c) => c.findIndex((p) => a.valueOf() === p.valueOf()) === b)
    .sort((a, b) => b.valueOf() - a.valueOf());
};

export type BalanceColumnKey = keyof NamespacePortfolioPropertyBalance["showColumns"];

export type BalanceValueHeaderConfig = {
  id: BalanceColumnKey;
  translationKey: TranslationKey;
  description?: string;
};

export const getBalanceValueHeaders = (showColumns: NamespacePortfolioPropertyBalance["showColumns"]): BalanceValueHeaderConfig[] =>
  (
    [
      { id: "totalCostWithVat", translationKey: "PORTFOLIO_BALANCE_SETTINGS_COLUMNS_VALUE" },
      { id: "change", translationKey: "PORTFOLIO_BALANCE_SETTINGS_COLUMNS_CHANGE" },
      { id: "changePercentage", translationKey: "PORTFOLIO_BALANCE_SETTINGS_COLUMNS_CHANGE_PERCENTAGE" },
    ] satisfies BalanceValueHeaderConfig[]
  ).filter((config) => showColumns[config.id]);

export const getColumnCount = (showColumns: NamespacePortfolioPropertyBalance["showColumns"]) => {
  const { change, changePercentage, totalCostWithVat } = showColumns;

  return Number(change) + Number(changePercentage) + Number(totalCostWithVat);
};

export const periodCompleted = (period: Moment, periodType: MetricPeriodType) => {
  const currentPeriod = fitDate(moment.utc(), periodType);

  return period.isBefore(currentPeriod);
};

export const getRowValues = (
  columns: Moment[],
  currentPeriod: Moment,
  budgetsMap: BudgetCategoryMap,
  preferences: NamespacePortfolioPropertyBalance
): RowValue[][] => {
  const headers = preferences.showColumns;

  const showForecasts = preferences.showBudgets && preferences.showBudgetForecasts;

  const { change, changePercentage, totalCostWithVat } = headers;

  const budgets = (period: Moment) => Array.from(budgetsMap.get(period.valueOf())?.values() ?? []);

  const budgetForecasts = (period: Moment) => {
    const periodCompleted = period.isBefore(currentPeriod);

    if (periodCompleted) return [];

    return Array.from(budgetsMap.get(period.valueOf())?.values() ?? []);
  };

  return columns.map((period, index) => {
    const values: RowValue[] = [];

    const periodSuffix = period.format("YYYY-MM-DD");

    if (totalCostWithVat) values.push({ type: "totalCostWithVat", value: 0, index, cellKey: "totalCostWithVat" + periodSuffix });

    if (change) values.push({ type: "change", value: 0, index, cellKey: "change" + periodSuffix });

    if (changePercentage) values.push({ type: "changePercentage", value: 0, index, cellKey: "changePercentage" + periodSuffix });

    if (showForecasts)
      values.push(
        ...budgetForecasts(period).map(
          (budget): BudgetForecastRowValue => ({
            type: "budgetForecast",
            index,
            cellKey: "forecast" + budget.id + periodSuffix,
            budgetId: budget.id,
            value: 0,
          })
        )
      );

    values.push(
      ...budgets(period).map((budget): BudgetRowValue => {
        return { type: "budget", index, cellKey: budget.id + periodSuffix, budgetId: budget.id, value: 0 };
      })
    );

    return values;
  });
};

export const getRowPeriodHeaders = (
  columns: Moment[],
  currentPeriod: Moment,
  budgetsMap: BudgetCategoryMap,
  preferences: NamespacePortfolioPropertyBalance
): RowPeriodHeader[] => {
  const getBudgetsCount = (period: Moment) => {
    if (!preferences.showBudgets) return 0;

    const periodCompleted = period.isBefore(currentPeriod);
    const factor = periodCompleted || !preferences.showBudgetForecasts ? 1 : 2;
    return (budgetsMap?.get(period.valueOf())?.size ?? 0) * factor;
  };

  return columns.map((period, index) => ({
    period,
    label: getPeriodHeader(period, preferences.periodType ?? null),
    colSpan: getColumnCount(preferences.showColumns) + getBudgetsCount(period),
    even: index % 2 === 0,
  }));
};

export const getRowValueHeaders = (
  columns: Moment[],
  currentPeriod: Moment,
  budgetsMap: BudgetCategoryMap,
  preferences: NamespacePortfolioPropertyBalance
): RowValueHeader[] => {
  const headers = preferences.showColumns;

  const valueHeadersRow = getRowValues(columns, currentPeriod, budgetsMap, preferences);

  const bothChangeColumnsEnabled = headers.change && headers.changePercentage;

  const showForecasts = preferences.showBudgets && preferences.showBudgetForecasts;

  const columnEnabled = (type: RowValueType) => (type !== "budgetForecast" || showForecasts) && (!bothChangeColumnsEnabled || type !== "changePercentage");

  const getBudgetName = (budgetId: string, period: Moment) => {
    return budgetsMap.get(period.valueOf())?.get(budgetId)?.description ?? "Budget";
  };

  const getColSpan = (type: RowValueType) => (type === "change" && bothChangeColumnsEnabled ? 2 : 1);

  const translator = useTranslator();

  const getLabel = (rowValue: RowValue) => {
    switch (rowValue.type) {
      case "change":
        return translator("PORTFOLIO_BALANCE_SETTINGS_COLUMNS_CHANGE");
      case "changePercentage":
        return translator("PORTFOLIO_BALANCE_SETTINGS_COLUMNS_CHANGE_PERCENTAGE");
      case "totalCostWithVat":
        return translator("PORTFOLIO_BALANCE_SETTINGS_COLUMNS_VALUE");
      case "budget":
        return getBudgetName(rowValue.budgetId, columns[rowValue.index]);
      case "budgetForecast":
        return getBudgetName(rowValue.budgetId, columns[rowValue.index]) + " \nforecast";
    }
  };

  return filterWithValue(
    valueHeadersRow.flatMap((column, index) =>
      column.map((rowValue): RowValueHeader | null =>
        columnEnabled(rowValue.type)
          ? {
              colSpan: getColSpan(rowValue.type),
              label: getLabel(rowValue),
              cellKey: rowValue.cellKey,
              type: rowValue.type,
              even: index % 2 === 0,
            }
          : null
      )
    )
  );
};

export const getValuesFlat = (
  values: ColumnDataObject[],
  columns: Moment[],
  currentPeriod: Moment,
  budgetsMap: BudgetCategoryMap,
  preferences: NamespacePortfolioPropertyBalance
): RowValues[] => {
  const valueHeadersRow = getRowValues(columns, currentPeriod, budgetsMap, preferences);

  return values.map((rowValue, index) => {
    const columnHeaders = valueHeadersRow[index];

    columnHeaders.forEach((column) => {
      switch (column.type) {
        case "budget":
          const budgetValues = rowValue.budgets[column.budgetId];

          column.value = budgetValues ? (rowValue.type === "results" ? budgetValues.value : budgetValues.total) : 0;
          break;
        case "budgetForecast":
          column.value = rowValue.budgetForecasts[column.budgetId] ?? 0;
          break;
        case "change":
          column.value = rowValue.change;
          break;
        case "changePercentage":
          column.value = rowValue.changePercentage;
          break;
        case "totalCostWithVat":
          column.value = rowValue.totalCostWithVat;
          break;
      }
    });

    return {
      isBlueprint: rowValue.isBlueprint,
      values: columnHeaders,
    };
  });
};

export type ExportData = { rows: ExportRows; periodHeaders: RowPeriodHeader[]; valueHeaders: RowValueHeader[] };

export const balanceExport = async ({ periodHeaders, rows, valueHeaders }: ExportData, header: string) => {
  const getExcelHeaderType = (type: RowValueType, colSpanIndex: number): ExcelHeader["type"] => {
    switch (type) {
      case "changePercentage":
        return "percentage";
      case "change":
        return colSpanIndex === 0 ? "number" : "percentage";
      default:
        return "number";
    }
  };

  const getExcelHeaderFormat = (type: RowValueType, colSpanIndex: number): ExcelHeader["format"] => {
    switch (type) {
      case "changePercentage":
        return "0.0%";
      case "change":
        return colSpanIndex === 0 ? undefined : "0.0%";
    }
  };

  const exportPeriodHeaders: ExcelHeader[] = [
    {
      label: header,
      rowSpan: 2,
      type: "string",
    },
  ];

  for (let i = 0; i < periodHeaders.length; i++) {
    const periodHeader = periodHeaders[i];

    for (let c = 0; c < periodHeader.colSpan; c++) {
      exportPeriodHeaders.push({ label: periodHeader.label, colSpan: c > 0 ? 1 : periodHeader.colSpan, type: "string" });
    }
  }

  const headers: ExcelHeader[] = [{ type: "string", label: "" }];

  for (let i = 0; i < valueHeaders.length; i++) {
    const v = valueHeaders[i];
    for (let c = 0; c < v.colSpan; c++) {
      headers.push({ label: v.label, colSpan: c > 0 ? 1 : v.colSpan, type: getExcelHeaderType(v.type, c), format: getExcelHeaderFormat(v.type, c) });
    }
  }

  const sheet = exportGenerateSheet(
    [
      [],
      [],
      ...rows.map((r): ExcelValueObject[] => [
        {
          __type: "ExcelValueObject",
          value: "  ".repeat(r.level) + (r.sum ? r.name + " sum" : r.name),
          bold: r.expanded,
          underline: r.sum,
          fill: r.sum ? "E1E1E1" : undefined,
        },
        ...r.values.map(
          (v): ExcelValueObject => ({
            __type: "ExcelValueObject",
            value: v.value || 0,
            bold: r.expanded,
            underline: r.sum,
            fill: r.sum ? "E1E1E1" : undefined,
          })
        ),
      ]),
    ],
    headers
  );

  exportAddSheetHeaderRow(sheet, exportPeriodHeaders, "A1");
  exportAddSheetHeaderRow(sheet, headers, "A2");

  exportDownloadSheet(sheet, "regnskab");
};

export const isZeroRow = (rowValues: RowValue[]) => rowValues.every((v) => Math.abs(v.value || 0) < 0.01);
