import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["maximumFractionDigits", "minimumFractionDigits"];
import "core-js/modules/es6.regexp.split.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import moment from "moment";
import { formatNumber, formatString, hasValue } from "~/helpers/common-helpers";
import { capitalize, capitalizeAll, monthYear, dateRange, year } from "~/helpers/filter-helpers";
import { convertArea } from "~/helpers/portfolio/portfolio-property-helpers";
export var useFilter = function useFilter() {
  var i18n = useI18n();
  return {
    quarter: function quarter(input) {
      return formatString(input, function (input) {
        var momentDate = moment(input);
        return "Q".concat(momentDate.quarter(), " ").concat(momentDate.year());
      });
    },
    month: function month(input) {
      return formatString(input, function (input) {
        return moment(input).format("MMM");
      });
    },
    year: year,
    monthYear: monthYear,
    dayMonthYear: function dayMonthYear(input) {
      return formatString(input, function (input) {
        return moment(input).format("DD MMM YYYY");
      });
    },
    integer: function integer(number) {
      return formatNumber(number, function (number) {
        return (number || 0).toFixed(0);
      });
    },
    area: function area(number, unit) {
      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref$per = _ref.per,
        per = _ref$per === void 0 ? false : _ref$per,
        convertFrom = _ref.convertFrom;
      var translateUnit = useTranslateUnit();
      var localeUnit = unit ? translateUnit(unit) : undefined;
      return formatNumber(number, function (number) {
        if (convertFrom && unit) number = convertArea(number, convertFrom, unit);
        return localeUnit ? "".concat(new Intl.NumberFormat(i18n.locale).format(number)).concat(per ? " /" : "", " ").concat(localeUnit) : "–";
      });
    },
    currency: function currency(number) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$currency = _ref2.currency,
        _currency = _ref2$currency === void 0 ? "DKK" : _ref2$currency,
        _ref2$mode = _ref2.mode,
        mode = _ref2$mode === void 0 ? "standard" : _ref2$mode,
        maxDigits = _ref2.maxDigits,
        minDigits = _ref2.minDigits,
        maxSignificants = _ref2.maxSignificants,
        perAreaUnit = _ref2.perAreaUnit,
        roundingIncrement = _ref2.roundingIncrement;
      var formatter = function formatter(number) {
        var result = new Intl.NumberFormat(i18n.locale, {
          style: "currency",
          currency: _currency,
          maximumSignificantDigits: hasValue(maxSignificants) ? maxSignificants : undefined,
          maximumFractionDigits: hasValue(maxDigits) ? maxDigits : mode === "compact" ? 1 : 0,
          minimumFractionDigits: minDigits ? minDigits : 0,
          roundingIncrement: hasValue(roundingIncrement) ? roundingIncrement : undefined,
          notation: mode === "compact" ? "compact" : undefined
        }).format(number);
        var translateUnit = useTranslateUnit();
        var localePerAreaUnit = perAreaUnit ? translateUnit(perAreaUnit) : undefined;
        return perAreaUnit ? "".concat(result, " / ").concat(localePerAreaUnit) : result;
      };
      return formatNumber(number, formatter);
    },
    percentage: {
      standard: function standard(number) {
        var formatter = function formatter(number) {
          return new Intl.NumberFormat(i18n.locale).format(number) + "%";
        };
        return formatNumber(number, formatter);
      },
      decimal: function decimal(number) {
        var formatter = function formatter(number) {
          return new Intl.NumberFormat(i18n.locale).format(number * 100) + "%";
        };
        return formatNumber(number, formatter);
      },
      compact: function compact(number) {
        var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref3$minDigits = _ref3.minDigits,
          minDigits = _ref3$minDigits === void 0 ? 1 : _ref3$minDigits;
        var formatter = function formatter(number) {
          return new Intl.NumberFormat(i18n.locale, {
            maximumFractionDigits: 1,
            minimumFractionDigits: minDigits
          }).format(number) + "%";
        };
        return formatNumber(number, formatter);
      }
    },
    capitalize: capitalize,
    capitalizeAll: capitalizeAll,
    uppercase: function uppercase(s) {
      return s.toUpperCase();
    },
    date: {
      short: function short(date) {
        return formatString(date, function (date) {
          return moment(date, "YYYY-MM-DD").local().format("Do MMM YYYY");
        });
      },
      long: function long(date) {
        return formatString(date, function (date) {
          return moment(date).local().format("Do MMM YYYY, hh:mm:ss");
        });
      },
      range: dateRange
    },
    timefrom: function timefrom(date) {
      return formatString(date, function (date) {
        var diff = Math.round(moment().diff(moment(date, "YYYY-MM-DD"), "months", true));
        var diffMonths = diff % 12;
        var diffYears = (diff - diffMonths) / 12;
        var output = [];
        if (diffYears !== 0) {
          output.push("".concat(diffYears, " ").concat(i18n.tc("TIMEUNIT_YEAR", diffYears)));
        }
        if (diffMonths !== 0) {
          output.push("".concat(diffMonths, " ").concat(i18n.tc("TIMEUNIT_MONTH", diffMonths)));
        }
        return output.join(", ");
      });
    },
    number: function number(_number) {
      var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref4$maximumFraction = _ref4.maximumFractionDigits,
        maximumFractionDigits = _ref4$maximumFraction === void 0 ? 0 : _ref4$maximumFraction,
        _ref4$minimumFraction = _ref4.minimumFractionDigits,
        minimumFractionDigits = _ref4$minimumFraction === void 0 ? 0 : _ref4$minimumFraction,
        rest = _objectWithoutProperties(_ref4, _excluded);
      return formatNumber(_number, function (number) {
        return new Intl.NumberFormat(i18n.locale, _objectSpread({
          maximumFractionDigits: maximumFractionDigits,
          minimumFractionDigits: minimumFractionDigits
        }, rest)).format(number);
      });
    },
    string: function string(input) {
      return "".concat(input !== null && input !== void 0 ? input : "");
    },
    lowercase: function lowercase(input) {
      return input.toLowerCase();
    },
    limitCharacters: function limitCharacters(input, limit) {
      var s = input || "";
      if (s.length > limit) {
        s = s.substring(0, limit);
        if (s[limit - 1] === " ") {
          s = s.substring(0, limit - 1);
        }
        return "".concat(s.substring(0, limit), "\u2026");
      }
      return s;
    },
    initials: function initials(input) {
      var _input;
      (_input = input) !== null && _input !== void 0 ? _input : input = "";
      var parts = input.split(" ");
      if (parts.length === 1) {
        return parts[0].charAt(0);
      }
      return parts[0].charAt(0) + parts[parts.length - 1].charAt(0);
    }
  };
};