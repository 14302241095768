import "core-js/modules/es6.array.find.js";
export var useTranslateCurrency = function useTranslateCurrency() {
  var i18n = useI18n();
  return function (currency) {
    var _Intl$NumberFormat$fo;
    return (_Intl$NumberFormat$fo = new Intl.NumberFormat(i18n.locale, {
      style: "currency",
      currency: currency
    }).formatToParts().find(function (p) {
      return p.type === "currency";
    })) === null || _Intl$NumberFormat$fo === void 0 ? void 0 : _Intl$NumberFormat$fo.value;
  };
};