import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.array.map.js";
import { convertArea } from "~/helpers/portfolio/portfolio-property-helpers";
import { getCurrentTenant, getCurrentTenantBalance, getExternalIdForTenancy, getLeaseEnd, getLeaseStart, getMonthlyRent, getMonthlyRentPerArea, getOnSaveTenanciesCustomTextField, getRentCurrency, getRentPerAreaPerAnnum, getRentRegulationPrinciple, getTenancyAddress, getTenancyBrokerMandate, getTenancyDaysOnMarket, getTenancyPortfolioColorBackground, getTenancyPortfolioColorForeground, getYearlyRent, stringifyCustomField } from "~/helpers/portfolio/portfolio-tenancy-helpers";
import { getLatestSalesFinances, getSalesStatusText } from "~/helpers/portfolio/portfolio-sales-helpers";
import { isFeatureSupported } from "~/plugins/feature-flags";
import moment from "moment";
import { AssetManagementCustomFieldType, AssetManagementTenancyNullOrder } from "~/graphql/generated/graphql";
export var useTenanciesTableHeaders = function useTenanciesTableHeaders(customFields) {
  var _filter = useFilter();
  var router = useRouter();
  var ampli = useAmplitude();
  var navigateToProperty = function navigateToProperty(id) {
    var properties = {
      hierarchy: "property",
      page: "overview"
    };
    ampli.navigateToTab(properties);
    router.push({
      name: "portfolio-property-propertyId",
      params: {
        propertyId: id
      }
    });
  };
  var navigateToUnit = function navigateToUnit(id) {
    var properties = {
      hierarchy: "tenancy",
      page: "overview"
    };
    ampli.navigateToTab(properties);
    router.push({
      name: "portfolio-tenancy-tenancyId",
      params: {
        tenancyId: id
      }
    });
  };
  var getCustomConfig = function getCustomConfig(field) {
    var _field$configuration$, _field$configuration;
    var id = field.id,
      name = field.name;
    var onSave = getOnSaveTenanciesCustomTextField(field);
    var inputType = field.fieldType === AssetManagementCustomFieldType.Number ? "number" : "text";
    var dataTableCellOptions = field.fieldType === AssetManagementCustomFieldType.Option ? {
      renderAs: "dropdown",
      onSave: onSave,
      options: (_field$configuration$ = (_field$configuration = field.configuration) === null || _field$configuration === void 0 ? void 0 : _field$configuration.options) !== null && _field$configuration$ !== void 0 ? _field$configuration$ : []
    } : field.fieldType === AssetManagementCustomFieldType.Boolean ? {
      renderAs: "checkbox",
      onSave: onSave
    } : {
      type: inputType,
      renderAs: "editable",
      copyType: inputType === "number" ? "number" : "body",
      filter: function filter(v) {
        var _field$configuration2;
        return inputType === "number" ? (_field$configuration2 = field.configuration) !== null && _field$configuration2 !== void 0 && _field$configuration2.currency ? _filter.currency(v, {
          currency: field.configuration.currency
        }) : _filter.number(v) : v !== null && v !== void 0 ? v : "";
      },
      onSave: onSave
    };
    return {
      id: stringifyCustomField(field),
      labelKey: undefined,
      label: name,
      value: function value(row) {
        var _row$assetManagementC, _field$configuration$2, _field$configuration3, _options$find, _tenancyCustomField$v;
        var tenancyCustomField = (_row$assetManagementC = row.assetManagementCustomFields) === null || _row$assetManagementC === void 0 ? void 0 : _row$assetManagementC.find(function (field) {
          var _field$customField;
          return ((_field$customField = field.customField) === null || _field$customField === void 0 ? void 0 : _field$customField.id) === id;
        });
        var options = (_field$configuration$2 = (_field$configuration3 = field.configuration) === null || _field$configuration3 === void 0 ? void 0 : _field$configuration3.options) !== null && _field$configuration$2 !== void 0 ? _field$configuration$2 : [];
        return field.fieldType === AssetManagementCustomFieldType.Option ? (_options$find = options.find(function (o) {
          return o === (tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : tenancyCustomField.value);
        })) === null || _options$find === void 0 ? void 0 : _options$find.toString() : tenancyCustomField === null || tenancyCustomField === void 0 ? void 0 : (_tenancyCustomField$v = tenancyCustomField.value) === null || _tenancyCustomField$v === void 0 ? void 0 : _tenancyCustomField$v.toString();
      },
      sortKey: id,
      sortRelation: "custom_field",
      sortOverrideNullOrder: field.fieldType === AssetManagementCustomFieldType.Boolean ? undefined : AssetManagementTenancyNullOrder.Last,
      textAlignEnd: field.fieldType === AssetManagementCustomFieldType.Number,
      minWidth: field.fieldType === AssetManagementCustomFieldType.Text || field.fieldType === AssetManagementCustomFieldType.Number ? "20rem" : undefined,
      dataTableCellOptions: dataTableCellOptions
    };
  };
  return computed(function () {
    var headers = [{
      id: "name",
      value: getTenancyAddress,
      labelKey: "PORTFOLIO_VIEW_TENANCIES_NAME",
      sortKey: "name",
      dataTableCellOptions: {
        renderAs: "link",
        navigate: function navigate(row) {
          return navigateToUnit(row.id);
        }
      },
      widthFractions: 2
    }, {
      id: "tenantName",
      value: function value(row) {
        var _getCurrentTenant$nam, _getCurrentTenant;
        return (_getCurrentTenant$nam = (_getCurrentTenant = getCurrentTenant(row)) === null || _getCurrentTenant === void 0 ? void 0 : _getCurrentTenant.name) !== null && _getCurrentTenant$nam !== void 0 ? _getCurrentTenant$nam : "";
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_TENANT_NAME",
      sortKey: "name",
      sortRelation: "tenant"
    }, {
      id: "unitNumber",
      value: getExternalIdForTenancy,
      labelKey: "PORTFOLIO_VIEW_TENANCIES_UNIT_NUMBER",
      sortKey: "id",
      sortRelation: "external_id",
      dataTableCellOptions: {
        renderAs: "label",
        copyType: "number",
        copyColor: function copyColor() {
          return computed(function () {
            return "--color-primary";
          });
        },
        labelColor: function labelColor() {
          return computed(function () {
            return "--color-accent-100";
          });
        }
      }
    }, {
      id: "usage",
      value: function value(row) {
        var _row$tenancyType;
        return (_row$tenancyType = row.tenancyType) !== null && _row$tenancyType !== void 0 ? _row$tenancyType : "";
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_USAGE",
      sortKey: "tenancy_type"
    }, {
      id: "numberOfRooms",
      value: function value(row) {
        var _row$rooms$toString, _row$rooms;
        return (_row$rooms$toString = (_row$rooms = row.rooms) === null || _row$rooms === void 0 ? void 0 : _row$rooms.toString()) !== null && _row$rooms$toString !== void 0 ? _row$rooms$toString : "";
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_ROOMS",
      sortKey: "rooms",
      dataTableCellOptions: {
        copyType: "number"
      },
      export: {
        type: "number"
      }
    }, {
      id: "area",
      value: function value(row) {
        var _row$area;
        return (_row$area = row.area) === null || _row$area === void 0 ? void 0 : _row$area.toString();
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_AREA",
      sortKey: "area",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value, row, context) {
          return _filter.number(_filter.integer(convertArea(value, row.areaUnit, context.areaUnit)));
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "salesStatus",
      value: function value(row) {
        return getSalesStatusText(row.status);
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_SALES_STATUS",
      sortKey: "status",
      dataTableCellOptions: {
        renderAs: "slot"
      }
    }, {
      id: "takeoverDate",
      value: function value(row) {
        var _getLatestSalesFinanc, _row$assetManagementS;
        return (_getLatestSalesFinanc = getLatestSalesFinances((_row$assetManagementS = row.assetManagementSales) === null || _row$assetManagementS === void 0 ? void 0 : _row$assetManagementS.items)) === null || _getLatestSalesFinanc === void 0 ? void 0 : _getLatestSalesFinanc.takeOverDate;
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_TAKEOVER_DATE",
      dataTableCellOptions: {
        filter: _filter.date.short
      }
    }, {
      id: "monthlyRent",
      value: function value(row) {
        var _getMonthlyRent;
        return (_getMonthlyRent = getMonthlyRent(row)) === null || _getMonthlyRent === void 0 ? void 0 : _getMonthlyRent.toString();
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_MONTHLY_RENT",
      textAlignEnd: true,
      sortKey: "rent_per_annum",
      sortRelation: "planned_rent",
      sortDirection: "DESC",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value, row) {
          var currency = getRentCurrency(row);
          if (!currency) return "–";
          return _filter.currency(value, {
            currency: currency
          });
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "monthlyRentPsm",
      value: function value(row) {
        var _getMonthlyRentPerAre;
        return (_getMonthlyRentPerAre = getMonthlyRentPerArea(row)) === null || _getMonthlyRentPerAre === void 0 ? void 0 : _getMonthlyRentPerAre.toString();
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_MONTHLY_RENT_M2",
      textAlignEnd: true,
      sortKey: "rent_per_annum_per_area",
      sortRelation: "planned_rent",
      sortDirection: "DESC",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value, row) {
          var currency = getRentCurrency(row);
          if (!currency) return "–";
          return _filter.currency(value, {
            currency: currency
          });
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "annualRent",
      value: function value(row) {
        return getYearlyRent(row).toString();
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_ANNUAL_RENT",
      textAlignEnd: true,
      sortKey: "rent_per_annum",
      sortRelation: "planned_rent",
      sortDirection: "DESC",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value, row) {
          var currency = getRentCurrency(row);
          if (!currency) return "–";
          return _filter.currency(value, {
            currency: currency
          });
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "rentPsm",
      value: function value(row) {
        var _getRentPerAreaPerAnn;
        return (_getRentPerAreaPerAnn = getRentPerAreaPerAnnum(row)) === null || _getRentPerAreaPerAnn === void 0 ? void 0 : _getRentPerAreaPerAnn.toString();
      },
      textAlignEnd: true,
      labelKey: "PORTFOLIO_VIEW_TENANCIES_RENT_M2",
      sortKey: "rent_per_annum_per_area",
      sortRelation: "planned_rent",
      sortDirection: "DESC",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value, row) {
          var currency = getRentCurrency(row);
          if (!currency) return "–";
          return _filter.currency(value, {
            currency: currency
          });
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "leaseStart",
      value: getLeaseStart,
      labelKey: "PORTFOLIO_VIEW_TENANCIES_LEASE_START",
      sortKey: "move_in_date",
      sortRelation: "tenant",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "leaseEnd",
      value: getLeaseEnd,
      labelKey: "PORTFOLIO_VIEW_TENANCIES_LEASE_END",
      textAlignEnd: true,
      sortKey: "eviction_date",
      sortRelation: "tenant",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "adminStart",
      value: function value(row) {
        return row.startDate;
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_ADM_START",
      textAlignEnd: true,
      sortKey: "start_date",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "adminEnd",
      value: function value(row) {
        return row.endDate;
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_ADM_END",
      textAlignEnd: true,
      sortKey: "end_date",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "propertyAdminStart",
      value: function value(row) {
        var _row$assetManagementP;
        return (_row$assetManagementP = row.assetManagementProperty) === null || _row$assetManagementP === void 0 ? void 0 : _row$assetManagementP.administrationStartDate;
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_PROPERTY_ADM_START",
      textAlignEnd: true,
      sortKey: "administration_start_date",
      sortRelation: "property",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "propertyAdminEnd",
      value: function value(row) {
        var _row$assetManagementP2;
        return (_row$assetManagementP2 = row.assetManagementProperty) === null || _row$assetManagementP2 === void 0 ? void 0 : _row$assetManagementP2.administrationEndDate;
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_PROPERTY_ADM_END",
      textAlignEnd: true,
      sortKey: "administration_end_date",
      sortRelation: "property",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "daysOnMarket",
      value: function value(row) {
        var _getTenancyDaysOnMark, _getTenancyDaysOnMark2;
        return (_getTenancyDaysOnMark = (_getTenancyDaysOnMark2 = getTenancyDaysOnMarket(row)) === null || _getTenancyDaysOnMark2 === void 0 ? void 0 : _getTenancyDaysOnMark2.toString()) !== null && _getTenancyDaysOnMark !== void 0 ? _getTenancyDaysOnMark : "–";
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_DAYS_ON_MARKET",
      textAlignEnd: true,
      dataTableCellOptions: {
        copyType: "number"
      },
      export: {
        type: "number"
      }
    }, {
      id: "brokerMandate",
      labelKey: "PORTFOLIO_VIEW_TENANCIES_BROKER_MANDATE",
      textAlignEnd: true,
      value: function value(row) {
        return getTenancyBrokerMandate(row).toString();
      },
      dataTableCellOptions: {
        copyType: "number"
      },
      export: {
        type: "number"
      }
    }, {
      id: "zip",
      labelKey: "PORTFOLIO_VIEW_TENANCIES_ZIP",
      value: function value(row) {
        var _row$assetManagementP3;
        return (_row$assetManagementP3 = row.assetManagementProperty) === null || _row$assetManagementP3 === void 0 ? void 0 : _row$assetManagementP3.zipCode;
      },
      sortKey: "zip_code",
      sortRelation: "property"
    }, {
      id: "portfolio",
      labelKey: "PORTFOLIO_VIEW_TENANCIES_PORTFOLIO",
      value: function value(row) {
        var _row$assetManagementP4, _row$assetManagementP5;
        return (_row$assetManagementP4 = row.assetManagementProperty) === null || _row$assetManagementP4 === void 0 ? void 0 : (_row$assetManagementP5 = _row$assetManagementP4.assetManagementPortfolio) === null || _row$assetManagementP5 === void 0 ? void 0 : _row$assetManagementP5.name;
      },
      sortKey: "name",
      sortRelation: "portfolio",
      dataTableCellOptions: {
        renderAs: "label",
        labelColor: function labelColor(row) {
          return computed(function () {
            return getTenancyPortfolioColorBackground(row);
          });
        },
        copyColor: function copyColor(row) {
          return computed(function () {
            return getTenancyPortfolioColorForeground(row);
          });
        }
      }
    }, {
      id: "arrears",
      labelKey: isFeatureSupported("arrearsBeta") ? "PORTFOLIO_VIEW_TENANCIES_ARREARS_BETA" : "PORTFOLIO_VIEW_TENANCIES_ARREARS",
      value: function value(row) {
        var _getCurrentTenantBala, _getCurrentTenantBala2;
        return (_getCurrentTenantBala = getCurrentTenantBalance(row)) === null || _getCurrentTenantBala === void 0 ? void 0 : (_getCurrentTenantBala2 = _getCurrentTenantBala.balance) === null || _getCurrentTenantBala2 === void 0 ? void 0 : _getCurrentTenantBala2.toString();
      },
      sortKey: "balance",
      sortRelation: "tenant",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value, row) {
          var _getCurrentTenantBala3;
          var currency = (_getCurrentTenantBala3 = getCurrentTenantBalance(row)) === null || _getCurrentTenantBala3 === void 0 ? void 0 : _getCurrentTenantBala3.currency;
          if (!currency) return "–";
          return _filter.currency(value, {
            currency: currency
          });
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "irrevocableUntilLandlord",
      labelKey: "PORTFOLIO_VIEW_TENANCIES_IRREVOCABLE_LANDLORD",
      value: function value(row) {
        var _getCurrentTenant2;
        return (_getCurrentTenant2 = getCurrentTenant(row)) === null || _getCurrentTenant2 === void 0 ? void 0 : _getCurrentTenant2.irrevocableUntilLandlord;
      },
      sortKey: "irrevocable_until_landlord",
      sortRelation: "tenant",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date",
        format: "dd-MM-yyyy"
      }
    }, {
      id: "irrevocableUntilTenant",
      labelKey: "PORTFOLIO_VIEW_TENANCIES_IRREVOCABLE_TENANT",
      value: function value(row) {
        var _getCurrentTenant3;
        return (_getCurrentTenant3 = getCurrentTenant(row)) === null || _getCurrentTenant3 === void 0 ? void 0 : _getCurrentTenant3.irrevocableUntilTenant;
      },
      sortKey: "irrevocable_until_tenant",
      sortRelation: "tenant",
      dataTableCellOptions: {
        filter: _filter.date.short
      },
      export: {
        type: "date"
      }
    }, {
      id: "leaseLength",
      labelKey: "PORTFOLIO_VIEW_TENANCIES_LEASELENGTH",
      value: function value(row) {
        var _getLeaseStart, _getLeaseEnd, _row$endDate;
        var tenant = getCurrentTenant(row);
        var today = moment();
        var leaseStart = moment((_getLeaseStart = getLeaseStart(row, tenant)) !== null && _getLeaseStart !== void 0 ? _getLeaseStart : today);
        var leaseEnd = moment((_getLeaseEnd = getLeaseEnd(row, tenant)) !== null && _getLeaseEnd !== void 0 ? _getLeaseEnd : today);
        var adminEnd = moment((_row$endDate = row.endDate) !== null && _row$endDate !== void 0 ? _row$endDate : today);
        return moment.min(adminEnd, leaseEnd).diff(leaseStart, "months").toString();
      },
      textAlignEnd: true,
      sortKey: "lease_length",
      sortRelation: "tenant",
      dataTableCellOptions: {
        copyType: "number",
        filter: function filter(value) {
          return _filter.number(value);
        }
      },
      export: {
        type: "number"
      }
    }, {
      id: "rentRegulationPrinciple",
      value: getRentRegulationPrinciple,
      labelKey: "PORTFOLIO_VIEW_TENANCIES_RENT_REGULATION",
      sortKey: "rent_regulation_principle"
    }, {
      id: "property",
      value: function value(row) {
        var _row$assetManagementP6;
        return (_row$assetManagementP6 = row.assetManagementProperty) === null || _row$assetManagementP6 === void 0 ? void 0 : _row$assetManagementP6.name;
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_PROPERTY",
      sortKey: "name",
      sortRelation: "property",
      dataTableCellOptions: {
        renderAs: "link",
        navigate: function navigate(row) {
          var _row$assetManagementP7, _row$assetManagementP8;
          return ((_row$assetManagementP7 = row.assetManagementProperty) === null || _row$assetManagementP7 === void 0 ? void 0 : _row$assetManagementP7.id) && navigateToProperty((_row$assetManagementP8 = row.assetManagementProperty) === null || _row$assetManagementP8 === void 0 ? void 0 : _row$assetManagementP8.id);
        }
      }
    }, {
      id: "assignedTo",
      value: function value(row) {
        var _row$assetManagementR, _row$assetManagementR2;
        return (_row$assetManagementR = (_row$assetManagementR2 = row.assetManagementResponsibles) === null || _row$assetManagementR2 === void 0 ? void 0 : _row$assetManagementR2.map(function (r) {
          return r === null || r === void 0 ? void 0 : r.name;
        }).join(",")) !== null && _row$assetManagementR !== void 0 ? _row$assetManagementR : "";
      },
      labelKey: "PORTFOLIO_VIEW_TENANCIES_ASSIGNED_TO",
      dataTableCellOptions: {
        renderAs: "slot"
      }
    }];
    for (var i = 0; i < customFields.value.length; i++) {
      headers.push(getCustomConfig(customFields.value[i]));
    }
    return headers;
  });
};