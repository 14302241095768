import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { MetricPeriodType } from "~/graphql/generated/graphql";
export var Operator = {
  Equals: "eq",
  GreaterThan: "gt",
  GreaterThanOrEqual: "gte",
  LessThan: "lt",
  LessThanOrEqual: "lte",
  In: "in",
  Contains: "ct",
  ContainsCaseInsensitive: "ict",
  StartsWith: "sw",
  StartsWithCaseInsensitive: "isw",
  EndsWith: "ew",
  EndsWithCaseInsensitive: "iew"
};
export var toWritableRef = function toWritableRef(urlParam) {
  return computed({
    get: function get() {
      return urlParam.value.value;
    },
    set: function set(newValue) {
      urlParam.setValue(newValue);
    }
  });
};
export var metricPeriodToMoment = _defineProperty(_defineProperty(_defineProperty({}, MetricPeriodType.Monthly, "month"), MetricPeriodType.Quarterly, "quarter"), MetricPeriodType.Yearly, "year");