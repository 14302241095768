// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containerPortal_cQv44{height:100vh;left:0;position:absolute;top:0;width:100vw}.containerPortalHeightLimit_Fq7Hi{height:calc(100% - 4rem);position:relative;width:100%}.emptyStateContainer_ffoGn,.emptyStateContainerInner_NxPYi{display:flex;flex-direction:column}.emptyStateContainer_ffoGn{align-items:center;height:calc(100vh - 6rem);justify-content:center;width:100%}.emptyStateContainerInner_NxPYi{height:-moz-fit-content;height:fit-content;width:-moz-fit-content;width:fit-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPortal": "containerPortal_cQv44",
	"containerPortalHeightLimit": "containerPortalHeightLimit_Fq7Hi",
	"emptyStateContainer": "emptyStateContainer_ffoGn",
	"emptyStateContainerInner": "emptyStateContainerInner_NxPYi"
};
module.exports = ___CSS_LOADER_EXPORT___;
