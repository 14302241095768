import { render, staticRenderFns } from "./default.vue?vue&type=template&id=67f3bda0"
import script from "./default.vue?vue&type=script&setup=true&lang=ts"
export * from "./default.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=67f3bda0&prod&module=true&lang=css"
import style1 from "./default.vue?vue&type=style&index=1&id=67f3bda0&prod&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/app/components/Loader/Loader.vue').default,ChromeSearch: require('/app/components/Chrome/ChromeSearch.vue').default,DropDownItem: require('/app/node_modules/@align/ui/dist/runtime/atoms/DropDown/DropDownItem.vue').default,DropDown: require('/app/node_modules/@align/ui/dist/runtime/atoms/DropDown/DropDown.vue').default,Chrome: require('/app/components/Chrome/Chrome.vue').default})
