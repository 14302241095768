import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.regexp.search.js";
import { defineStore } from "pinia";
export var useSearchStore = defineStore("search", function () {
  var router = useRouter();
  var ampli = useAmplitude();
  var searchTerm = ref("");
  var results = ref([]);
  var selection = ref(-1);
  var selectedItem = ref();
  var showingSelected = computed(function () {
    var _selectedItem$value;
    return searchTerm.value === ((_selectedItem$value = selectedItem.value) === null || _selectedItem$value === void 0 ? void 0 : _selectedItem$value.search.name);
  });
  var updateSelection = function updateSelection(val) {
    var newSelection = selection.value + val;
    if (newSelection < -1) {
      selection.value = results.value.length - 1;
    } else if (newSelection > results.value.length - 1) {
      selection.value = -1;
    } else {
      selection.value = newSelection;
    }
  };
  var selectItem = function selectItem() {
    var item = results.value[selection.value];
    if (!item) return;
    selectedItem.value = item;
    var params;
    var name;
    if (item.type === "ASSET_MANAGEMENT_PORTFOLIO") {
      name = "portfolio-portfolioId";
      params = {
        portfolioId: "".concat(item.search.id)
      };
    } else if (item.type === "ASSET_MANAGEMENT_PROPERTY") {
      name = "portfolio-property-propertyId";
      params = {
        propertyId: "".concat(item.search.id)
      };
    } else if (item.type === "ASSET_MANAGEMENT_TENANCY") {
      name = "portfolio-tenancy-tenancyId";
      params = {
        tenancyId: "".concat(item.search.id)
      };
    }
    ampli.searchSelect({
      searchTerm: searchTerm.value,
      name: item.search.name,
      id: item.search.id,
      type: item.type
    });
    searchTerm.value = item.search.name;
    router.push({
      name: name,
      params: params
    });
  };
  return {
    searchTerm: searchTerm,
    results: results,
    selectedItem: selectedItem,
    selection: selection,
    showingSelected: showingSelected,
    updateSelection: updateSelection,
    selectItem: selectItem
  };
});